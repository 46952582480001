/*============ page templates ============*/
/****** SHARED ******/
.page-graphic {
    display:block;
    position:relative;
    color: #FFF;
    background-color: var(--navy);
    background-size:cover;
    background-position:center;
	&.has-img {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 400px;
		min-height: clamp(300px, 21vw, 500px);
		.contentwrap {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-grow: 1;
	        z-index: 2;
	    }
	    &::after {
	        @include overlay;
	    }
	}
	.page-title {
		font-family: var(--font-title);
		line-height: 1;
		font-weight: bold;
		font-size: 3rem;
		margin: 0px;
	}
}

ul.breadcrumbs {
	@include content_wrap;
	font-size: 14px;
	font-size: 1vw;
	padding: 1em 30px;
	padding-top: 0px;
    list-style: none;
    z-index: 2;
    li {
        display: inline-block;
        a,
        a:visited {
            color: inherit;
            text-decoration: none;
            transition: color $quick ease;
            &:hover {
                color: #000;
            }
        }
        &:after {
			content:">";
			display: inline-block;
			vertical-align: middle;
			padding: 0px 5px;
        }
        &:last-of-type:after {
            display: none;
        }
    }
}


/*============ Home ============*/
.tint {
	background-color: var(--tint);
}
.underline {
	position: relative;
	line-height: 0.7;
	text-transform: uppercase;
	display: inline-block;
	&:after {
		content:"";
		position: relative;
		display: inline-block;
		width: 100%;
		height: 5px;
		background-color: var(--navy);
	}
}

/*** banners generic ***/
.banners {
	a,
	a:visited {
		color: #FFF;
		text-decoration: none;
	}
	a {
		&[data-colour="turquoise"]{
			background-color: var(--turquoise);
		}
		&[data-colour="sky-blue"]{
			background-color: var(--sky-blue);
		}
		&[data-colour="orange"]{
			background-color: var(--orange);
		}
		&[data-colour="teal"]{
			background-color: var(--teal);
		}
		&[data-colour="teal-blue"]{
			background-color: var(--teal-blue);
		}
		&[data-colour="navy"]{
			background-color: var(--navy);
		}
	}
	.banner-content {
		line-height: 1.6;
	}
}
.banner-navigation {
    display: none;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    > div {
        @include content_wrap;
        text-align: center;
    }
}
.banner-nav-item {
    @include dot($col: #FFF);
    > span {
        display: none !important;
    }
}

/*  main banner */
.banners-home_top {
	position: relative;
	background-color: $grey;
	background: linear-gradient(135deg, #0A2837 40%, #2A576A, #7FA9BA 80%);
	.banner-stage {
		position: relative;
	    padding-top: 45%;
		box-sizing: border-box;
    }
    .banner {
        position: absolute;
	    top:0;
	    left:0;
	    right:0;
	    bottom:0;
	    display: flex;
	    flex-direction: column;
	    justify-content: center;
	    text-decoration: none;
	    background-position:center;
	    background-size:cover;
	    padding-bottom: 80px; /* banner-nav */
	    z-index: 0;
	    opacity: 0;
	    transition: opacity $quick ease;
	    &.active {
	        z-index: 1;
	        opacity: 1;
	    }
	    &::before {
	        @include overlay;
	    }
	    &::after {
	        content: "";
	        position: absolute;
	        left: 0px;
	        bottom: 0px;
			display: block;
			width: 35%;
			height: 100%;
			background-image: url('../assets/connection-lines.svg');
			background-size: contain;
			background-position: bottom left;
			background-repeat: no-repeat;
	    }
    }
    a[href]{
        .banner-content {
            .post-content {
                display: block;
            }
        }
    }
    .banner-content {
        @include content_wrap;
		padding-right: 600px; /* to appear like a column */
        font-family: var(--font-heading);
        font-size: 1.4rem;
		letter-spacing: 0.48px;
		line-height: 1.2;
		z-index: 1;
		.post-content {
			display: none;
		}
    }
    .banner-name {
        display: block;
        font-family: var(--font-title);
        line-height: 1;
        font-size: 3rem;
        margin-bottom: 20px;
    }
    .button-hollow {
        @include button_hollow($txt: "navy", $colour-name: #FFF);
        margin-top: 2em;
    }
}

/** partner logos **/
.banners-home_partners {
	position: relative;
	overflow: hidden;
	.banner-stage {
		white-space: nowrap;
		a.banner-image {
			background-color: transparent; /* no data-colour plz */
            position: relative;
            display: inline-block;
            vertical-align: middle;
            filter: grayscale(100%);
            transition: filter $quick ease;
            overflow: hidden;
            margin: 0px 30px;
            .banner-content {
                height: 70px;
                max-width: 200px;
            }
            img {
                width: 100%;
                height: 100% !important;
                object-fit: contain;
                object-fit: scale-down;
            }
            &:hover {
                filter: grayscale(0%);
            }
		}
		.banner-name {
			display: none;
		}
	}
}

/** scroller generic **/
.scrollwrap {
	position: relative;
	@include content_wide();
	background-color: inherit;
	overflow: hidden;
	.prev,
	.next {
		display: none; /* show with js */
		position: absolute;
		background-color: inherit;
		cursor: pointer;
		top: 0px;
		bottom: 0px;
		width: 30px;
		z-index: 3;
		&::before {
			content:"";
			display: block;
			position: absolute;
			top: 50%;
			left: 10px;
			margin-top: -10px;
			width: 20px;
			height: 20px;
			border-top: 2px solid $grey;
			border-right: 2px solid $grey;
			border-radius: 2px;
		}
	}
	.prev {
		left: 0px;
		&::before {
			transform: rotate(227deg);
			left: 12px;
		}
	}
	.next {
		display: block;
		right: 0px;
		&::before {
			transform: rotate(45deg);
			left: 0px;
		}
	}
}
.scroller {
	white-space: nowrap;
	overflow-x: auto;
	> div {
		display: inline-block;
		white-space: normal;
	}
}
.scrollnav {
	display: none; /* shown with js */
	text-align: center;
	> span {
		@include dot;
	}
}
.js .scroller {
	overflow: hidden;
}
/** services scroller **/
.services.tint {
	padding: 30px 0px;
	padding-top: 3%;
	.next {
		display: block;
	}
	.scroller {
		width: auto;
		display: flex; /* match height */
		scroll-behavior: smooth;
		.item {
			display: inline-flex;
			flex-direction: column;
			width: 25%;
			min-width: 300px;
			flex-grow: 0;
			flex-shrink: 0;
		}
	}
}


/*============ page ============*/
@mixin class_buttons(){
	&.navy {
		.button_solid {
			@include button_solid($colour-name: "navy");
		}
		.button_hollow {
			//navy default
			@include button_hollow;
		}
	}
	&.turquoise {
		.button_solid{
			@include button_solid($colour-name: "turquoise");
		}
		.button_hollow{
			@include button_hollow($colour-name: "turquoise");
		}
	}
	&.teal {
		.button_solid{
			@include button_solid($colour-name: "teal");
		}
		.button_hollow{
			@include button_hollow($colour-name: "teal");
		}
	}
	&.teal-blue {
		.button_solid{
			@include button_solid($colour-name: "teal-blue");
		}
		.button_hollow{
			@include button_hollow($colour-name: "teal-blue");
		}
	}
	&.sky-blue {
		.button_solid{
			@include button_solid($colour-name: "sky-blue");
		}
		.button_hollow{
			@include button_hollow($colour-name: "sky-blue");
		}
	}
	&.orange {
		.button_solid {
			// orange default
			@include button_solid;
		}
		.button_hollow {
			@include button_hollow($colour-name: "orange");
		}
	}
}
.page-content {
	@include class_buttons;
}

/*=========== contact ===========*/
.office {
	.show {
		a {
			text-decoration: underline;
			color: var(--orange);
			&::after { /* pin img */
				content:"";
				display: inline-block;
				vertical-align: middle;
				width: 1em;
				height: 1em;
				background-image:url('../assets/pin.svg');
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				margin-left: 5px;
			}
		}
	}
}
/** contact form **/
.form-wrap {
	padding: 40px 0px;
}
label.above {
	text-align: left;
	line-height: 39px;
	height: 39px;
}
form {
	.flex {
		gap: 30px;
	}
	.col {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 45%;
		&:last-of-type {
			display: flex;
			flex-direction: column; // fill height
			align-items: flex-end;
			> p {
				flex-grow: 1;
				display: flex;
				flex-direction: column; // fill height
				width: 100%;
				box-sizing: border-box;
				textarea {
					flex-grow: 1;
				}
			}
		}
	}
}
/** Note: rest of careers styles in forms */
.t-careers {
	.form-wrap {
		padding-top: 0px;
		.contentwrap {
			padding-top: 0px;
		}
	}
}

/****** map ******/
.map-block {
	background-color: var(--tint);
	padding-bottom: 5%;
	.flex.title {
		justify-content: flex-start;
		padding-bottom: 20px;
		max-width: 1200px;
		margin: 0 auto;
		h3 {
			margin: 10px 0px;
			margin-right: 10px;
		}
	}
}
nav.map {
	li {
		position: relative;
		&::before {
			content:"";
			position: absolute;
			top: 10px;
			bottom: 10px;
			display: block;
			width: 1px;
			background-color: var(--navy);
		}
		a {
			position: relative;
			line-height: 1;
		}
	}
}
a.map-link {
	cursor: pointer;
	&.active {
		color: var(--orange);
	}
}

.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 280px;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}


/*** media queries ***/
@media only screen and (max-width: 1200px){
/* home */
	.banners-home_top {
		.banner-stage {
			padding-top: 60%;
			min-height: 500px;
		}
		.banner {
			&::after { /* vector */
				width: 50%;
			}
		}
		.banner-content {
			padding-right: 50%;
		}
	}
	ul.breadcrumbs {
	font-size: 14px;
	}
}

@media only screen and (max-width: 960px){
	.banners-home_top .banner-name,
	.page-graphic .page-title {
		font-size: 2em;
	}
	.page-graphic.has-img {
		min-height: 300px;
		min-height: clamp(150px, 30vw, 300px);
	}
	.banners-home_top {
		.banner-content {
			padding-right: 30px;
		}
		.arc-container {
			display: none;
		}
	}
}

@media only screen and (max-width: 760px){
/*** contact ***/
	#map-click {
        display: block;
    }
    label.above {
        height: auto;
		line-height: 1;
    }
	form {
		/* disable all flex */
		.flex,
		.col {
			display: block;
			width: 100%;
			box-sizing: border-box;
		}
		.col:last-of-type {
			display: block;
			padding-top: 5px;
			> p {
				display: block;
				textarea {
					display: block;
				}
			}
		}
	}
}

@media only screen and (max-width: 500px){
/* service scroller item full width */
	.services.tint {
		.scroller .item.pce_service {
			width: 100%;
			max-width: 100%;
			min-width: 0px;
		}
	}
}