.arc-container {
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    width:50%;
    overflow:hidden;
}
.segment-mask {
    width: 100%;
    height: 130%;    
    top:-15%;
    right:-15%;
    position: absolute;    
    z-index:1000;
    svg {
        width:100%;
        height:100%;
    }
}