/** all blocks **/
.related-blocks {
	div.block {
		min-height: 300px;
		box-sizing: border-box;
	}
}

/*============ Column Blocks shared ============*/
.block {
	position: relative; /* for dark img */
	.contentwide {
		position: relative; /* for title */
		padding-top: 0px;
		padding-bottom: 0px;
		.contentwrap {
			position: static;
			padding-top: 0px;
			padding-bottom: 0px;
		}
	}
	.title {
		position: absolute;
		top: 0px;
		left: 30px; /* of contentwide */
		max-width: 230px;
		box-sizing: border-box;
	}
	.title,
	.col-content {
		padding: clamp(50px, 10vw, 150px) 0px;
	}
	.col-content,
	.last {
		display: inline-block;
		width: 600px; /* half contentwrap */
		box-sizing: border-box;
	}
	.col-content {
		padding-right: 8%;
		font-weight: bold;
		/* align the content with title */
		h1, h2, h3, h4, p {
			margin-top: 16px;
		}
		h3 { /* match underline style */
			line-height: 0.7;
		}
		h4, p {
			line-height: 1.5;
		}
	}
}
.light,
.dark {
	.last {
		&::before { // add to height for image
			content:"";
			position: relative;
			display: block;
			width: 100%;
			padding-top: 100%;
		}
	}
}
/** light **/
.light {
	.img {
		position: relative;
		picture.bg {
			img {
				object-fit: contain;
			}
		}
	}
}

/** dark **/
.dark {
	@include white_txt();
	background-color: var(--teal-blue);
	.img {
		position: absolute;
		top: 0px;
		left: 50%; /* of screen */
		width: 50%;
		height: 100%;
	}
}

/** Testimonial scroller **/
.testimonial-scroller {
	.last {
		background-color: #FFF; /* inherited by scroller */
		padding-top: clamp(50px, 10vw, 150px);
		padding-bottom: 40px;
	}
	.scrollwrap {
		width: 100%;
		max-width: 100%;
		padding-left: 0px;
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.prev {
		display: none;
	}
	.scroller {
		position: relative;
		height: 300px; /* reset with js */
		.testimonial {
			position: relative;
			top: 0px;
			left: 0px;
			width: 100%;
		}
	}
	.scrollnav {
		text-align: left;
	}
}
/* javascript mode */
.js .testimonial-scroller {
	.scroller {
		.testimonial {
			position: absolute;
			opacity: 0;
			transition: opacity $quick ease;
			&.active {
				opacity: 1;
			}
		}
	}
}


@media only screen and (max-width: 1830px){
	.block {
		.contentwide {
			position: static;
			padding: 0px;
			width: 100%;
		}
		.flex {
			align-items: center;
		}
		.title {
			position: static;
			left: 0px;
			max-width: 100%;
			padding: 0px;
			> h3 {
				margin-top: 0px;
			}
		}
		.col-content,
		.last {
			width: 50%;
		}
	}
	.light,
	.dark {
		.last {
			&::before { // reduce img height
				padding-top: 50%;
			}
		}
	}
}

@media only screen and (max-width: 1200px){
	.dark {
		.img {
			img {
				object-fit: contain;
			}
		}
	}
}

@media only screen and (max-width: 960px){
	.related-blocks {
		div.block {
			min-height: 0px;
		}
	}
	.block {
		padding: 50px 0px;
		.flex {
			display: block;
			text-align: center;
		}
		.col-content,
		.last {
			width: 100%;
			padding: 0px;
		}
		.last {
			margin-top: 50px;
		}
	}
	.dark {
		&.block {
			padding-bottom: 0px;
		}
		.last {
			&::before {
				padding-top: 0px;
			}
		}
		.img {
			position: relative;
			left: 0px;
			width: 100%;
			padding-top: 58%;
		}
	}
}