
/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
    p {
	    position: relative;
	    text-align: left;
	    margin: 5px 0;
	}
	button { // navy
	    @include button_hollow;
	}
	/* file uploads */
	.file-browse {
		color: var(--turquoise);
	}
	.file-remove {
		color: var(--orange);
	}
}
label.float,
input {
    height: 40px;
    line-height: 40px;
}

input,
textarea {
    text-align: left;
    color: var(--orange);
    font-family: var(--font-heading);
    font-size: 16px;
    line-height: 1;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--navy);
    box-sizing: border-box;
}
input {
	/* longest label on contact form */
	padding-right: 85px;
}
textarea {
	padding-left: 20px;
    min-height: 80px;
    border: 1px solid var(--navy);
    border-radius: $border-radius;
}

.js-float-wrap {
    position:relative;
    &.focused,
    &.populated {
        label.float {
            left: auto;
		    right: 0px;
		    /*line-height: 1;
		    font-size: 0.75em;*/
		    &.left {
		        right: auto;
		        top: -30px;
		    }
        }
    }
}
label {
	font-family: var(--font-heading);
	&.error {
		display: block;
	    font-size: 12px;
	    line-height: 1;
	}
	&.float {
		position: absolute;
	    left:0;
	    color: inherit;
	    max-width: 100%;
	    box-sizing: border-box;
	    transition: all $quick linear;
	    z-index: 1;
	    &.left {
		    padding-left: 20px;
	    }
	}
}

/** file upload **/
p.file-wrap {
	min-height: 40px;
	border-bottom: 1px solid var(--navy);
	label {
		float: left;
		display: inline-block;
		line-height: 40px;
	}
}
.file-upload-container {
	padding-left: 10px;
	padding-top: 5px;
	text-align: right;
	box-sizing: border-box;
	a {
		font-weight: bold;
	}
}

.recaptcha-notice {
	padding-top: 20px;
	a {
		color: $grey;
	}
}
#form-result{
	font-weight: 700;
	text-align: center;
}

/*** media queries ***/
@media only screen and (max-width: 760px){
/* float labels above */
	input,
	label.float {
		padding-left: 20px;
	}
	input {
		padding-right: 0px;
		border: 1px solid var(--navy);
        border-radius: $border-radius;
	}
	/* file upload */
	p.file-wrap {
		padding-top: 1em;
		margin: 5px 0px;
		border-bottom: 0px;
		label {
			padding-left: 20px;
		}
	}
	.file-upload-container {
		min-height: 40px;
		padding-right: 5px;
		border: 1px solid var(--navy);
        border-radius: $border-radius;
	}
	form button {
		margin-top: 5px;
	}
	.js-float-wrap {
		padding-top: 1em;
		margin: 5px 0px;
		&.focused,
	    &.populated {
	        label.float {
	            top: 0px;
	            left: 0px;
			    right: auto;
			    line-height: 1;
			    &.left {
			        right: auto;
			        top: 0px;
			    }
	        }
	    }
	}
}