/** menu generic **/
nav {
	font-family: var(--font-heading);
	ul {
		list-style:none;
	    padding: 0;
	    margin: 0;
	}
	li {
	    display: inline-block;
	    font-size: 1.12em;
		letter-spacing: 0.44px;
	    box-sizing: border-box;
	    > ul {
	        display: none;
	    }
	    a {
			display: block;
		    color:inherit;
		    text-decoration: none;
		    padding: 10px;
		    transition: color $quick ease, background-color $quick ease;
		    &:hover {
		        color: var(--turquoise);
		    }
		}
	}
	&.slide-menu {
		li {
			position: relative;
	        display: block;
	        font-size: 1rem;
			line-height: 1;
		}
	}
}

a.logo {
    display: inline-block;
    width: 200px;
    max-width: 100%;
    height:auto;
    h1 {
        font-size: 1rem;
        margin: 0px;
    }
}

/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    margin: 5px;
}

/*============ header ============*/
@mixin bg_hover_colours {
	/* brand colour overrides */
    &.sky-blue {
        a {
            background-color: var(--sky-blue);
            &:hover {
                color: var(--sky-blue);
                background-color: #FFF;
            }
        }
    }
    &.orange {
        a {
            background-color: var(--orange);
            &:hover {
				color: var(--orange);
				background-color: #FFF;
            }
        }
    }
    &.teal {
        a {
            background-color: var(--teal);
            &:hover {
                color: var(--teal);
                background-color: #FFF;
            }
        }
    }
    &.turquoise {
        a {
            background-color: var(--turquoise);
            &:hover {
                color: var(--turquoise);
                background-color: #FFF;
            }
        }
    }
    &.navy {
        a {
            background-color: var(--navy);
            &:hover {
                color: var(--navy);
                background-color: #FFF;
            }
        }
    }
    &.teal-blue {
        a {
            background-color: var(--teal-blue);
            &:hover {
                color: var(--teal-blue);
                background-color: #FFF;
            }
        }
    }
}

header {
	background-color: #FFF;
	.contentwrap,
	.contentwide {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.contentwide {
		position: relative;
	}
	a.logo {
		position: absolute;
		top: 0px;
		left: 30px; /* of contentwide */
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.button {
		@include button_hollow($colour-name: "orange");
		position: absolute;
		right: 30px; /* contentwide */
		margin-left: 30px;
		margin-bottom: 30px;
	}
	.contentwrap {
		position: static;
		display: flex;
		justify-content: space-evenly;
		justify-content: space-apart;
		align-items: flex-end;
		min-height: 100px; /* for logo */
	}
	nav {
		padding-top: 10px;
		display: inline-flex;
		margin-left: auto;
		li {
		    li {
		        display: block;
		        a {
		            color: #FFF;
		            background-color: var(--navy);
		            padding: 10px 20px;
		        }
		        a:hover {
		            color: var(--navy);

	                background-color: #FFF;
		        }
		        @include bg_hover_colours;
		    }
		}
		/* top level only */
		> ul {
			position: relative;
			min-width: 500px; /* for sub-menu */
			> li {
				padding-bottom: 20px;
				> a {
					padding: 10px 20px;
					&:hover {
						color: var(--orange);
					}
				}
				&.has-sub {
					> a {
						display: inline-block;
						vertical-align: middle;
						padding-right: 2px;
					}
					&::after { //down arrow
						content:"";
						display: inline-block;
						vertical-align: middle;
						width: 8px;
						height: 8px;
						margin: 5px;
						border-top: 2px solid var(--navy);
						border-right: 2px solid var(--navy);
						transform: rotate(135deg);
						transition: transform $quick ease, border-color $quick ease;
					}
					&:hover {
						/* list is open */
						> a {
							color: var(--orange);
						}
						> ul {
							display:block;
						}
						&::after { //up arrow
							border-color: var(--orange);
							transform: rotate(315deg);
						}
					}
					> ul {
						display: none;
					    position: absolute;
					    top: 100%;
					    left: 0px;
					    /*right: 0px;*/
					    /*width: 1200px;*/
					    width: 500px;
					    /*max-width: 100%;*/
		                margin: 0 auto;
					    background: #FFF;
					    border: 1px solid var(--tint);
					    border-top: 0px;
					    z-index:10000;
					    text-align: left;
				    }
				}
			}
		}
	}
}

/** mobile icon **/
#mobile-menu {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index:100;
    > span {
	    display: block;
	    width: 100%;
	    height: 2px;
	    border-radius: 2px;
	    background-color: var(--navy);
	    margin: 6px 0;
	    transition: all 0.5s ease;
	}
}
.slide-menu-active {
	#mobile-menu {
		span.one {
		    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
		    transform: rotate(-45deg) translate(-6px, 6px);
		}
		span.three {
		     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
		    transform: rotate(45deg) translate(-6px, -6px) ;
		}
		span.two {
		    opacity: 0;
		}
	}
}

/*============ Footer ============*/

footer {
	@include white_txt;
	.contentwrap {
		padding-top: 0px;
	}
	h4 {
		font-size: 1.3em;
		color: var(--turquoise);
	}
	.office {
		p {
			margin: 10px 0px;
		}
	}
	nav {
		li {
			/* hide non-existant item */
			a[href="#"] {
				display: none;
			}
		}
	}
}
.footer-top {
    background-color: var(--navy);
    .contentwide {
        position: relative; /* for logo */
        .contentwrap {
            position: static; /* for logo */
            padding-bottom: 0px;
        }
        a.logo {
            position: absolute;
			width: 170px;
			top: 0px;
			left: 30px; //padding
		}
    }
}
.footer-bottom {
	font-size: 14px;
	background-color: var(--teal-blue);
	.contentwide {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.flex {
		justify-content: center;
		justify-content: space-evenly;
		align-items: center;
		> span {
			margin: 5px 10px;
		}
	}
}
// footer-promo
.footer-promo {
	padding: 30px 20px;
	box-sizing: border-box;
	background-color: var(--orange);
	@include white_txt;
	.button_hollow {
		@include button_hollow($txt: var(--orange), $colour-name: #FFF);
	}
	h3 {
		font-size: 1.8rem;
		margin: 0px;
	}
}
.footer-content {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
	justify-content: space-evenly;
	justify-content: space-between;
	padding-bottom: 40px;
	box-sizing: border-box;
	> .col {
		flex-grow: 1;
		flex-shrink: 1;
		&.first {
			flex-basis: 30%;
			box-sizing: border-box;
		}
	}
}
.footer-text {
	padding-top: 10px;
	p:first-of-type {
		margin-top: 0px;
	}
}
.subscribe {
	padding-top: 40px;
	text-align: right;
	h4 {
		display: inline-block;
		margin: 10px;
	}
}

/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: inherit;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}


/***** Slide menu ****/
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
.slide-menu-active {
	.site-content-menu-slide {
		right: 400px;
	}
	.slide-menu {
		right: 0px;
	}
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.slide-menu {
    display: none;
    background: var(--tint);
	position: fixed;
	width:400px;
	height: 100%;
	top: 0;
	right: -400px;
	overflow-y: auto;
	box-sizing: border-box;
	border-left: 1px solid var(--navy);
	z-index:1000;
	.menu-title {
		font-size: 18px;
		padding: 1em;
		color: #FFF;
		background-color: var(--navy);
		margin:0;
	}
	/** top level only **/
	> ul {
		> li {
			> a {
				font-size: 18px;
				border-bottom: 1px solid var(--navy);
			}
		}
	}
	li {
		a {
			padding: 18px;
		}
		&.expand-active {
			> ul {
				display: block;
			}
			> .expand i {
			    -webkit-transform: rotate(180deg);
			    -moz-transform: rotate(180deg);
			    -ms-transform: rotate(180deg);
			    -o-transform: rotate(180deg);
			    transform: rotate(180deg);
			}
		}
		/** all sub items **/
		ul {
			color: #FFF;
			background-color: var(--navy);
		}
		li {
			a {
				color: #FFF;
				background-color: var(--navy);
				min-height: 55px;
				border-bottom: 1px solid var(--tint);
				&:hover {
					color: var(--navy);
					background-color: #FFF;
				}
			}
			@include bg_hover_colours;
			&.has-sub > a {
				position: relative;
			    border-right: 1px solid var(--navy);
			    margin-right: 50px; /* space for drop */
			    &:after {
				    position: absolute;
				    content: "";
				    display: block;
				    left: 100%;
				    bottom: -1px;
				    background-color: var(--navy);
				    width: 50px;
				    height: 1px; /* border bottom */
				    z-index: 100;
				}
			}
		}
	}
	.expand {
	    position: absolute;
	    top: 0;
	    right: 0;
	    border-left: 1px solid #030b16;
		height: 55px;
	    width: 50px;
	    cursor: pointer;
	    i {
		    content:"";
		    display: block;
		    height: 100%;
		    width: 100%;
		    background: url('../assets/menu-drop.svg') no-repeat center center;
		    background-size: 30%;
		    -webkit-transition: -webkit-transform 500ms ease;
		    transition: -webkit-transform 500ms ease;
		    transition: transform 500ms ease;
		    transition: transform 500ms ease, -webkit-transform 500ms ease;
		}
	}
}

@media only screen and (max-width: 1660px) {
/** header **/
	header {
		.contentwide {
			position: static;
			padding: 0px;
			width: 100%;
		}
		.contentwrap {
			min-height: 0px;
		}
		a.logo {
			position: static;
			left: 0px;
		}
		.button {
			position: static;
			right: 0px;
		}
	}
/** footer **/
	.footer-top {
		.contentwide {
			position: static;
			padding: 30px 0px;
			width: 100%;
			.contentwrap {
				position: relative;
			}
			a.logo {
				position: static;
				width: 150px;
				left: 0px;
			}
		}
	}
}

@media only screen and (max-width: 960px) {
    header {
        .main {
            display:none;
        }
        .contentwrap {
            display: block;
        }
        .button {
            display: none;
            margin-bottom: 0px;
        }
    }
    #mobile-menu,
    .slide-menu{
        display:block;
    }
}

@media only screen and (max-width: 760px){
	footer {
		.subscribe {
			padding-top: 30px;
			text-align: center;
		}
		.main {
			display: none;
		}
		nav.footer {
			text-align: center;
		}
		.office {
			font-size: 14px;
			p {
				margin: 5px 0px;
			}
		}
	}
	.footer-top {
		.contentwide {
			padding-top: 0px;
		}
	}
	.footer-promo {
		padding: 20px;
		h3 {
			font-size: 1.2em;
			margin-bottom: 20px;
		}
	}
	.footer-content {
		display: block;
		text-align: center;
		padding-bottom: 0px;
		.offices{
			justify-content: center;
		}
	}
	.footer-text {
		font-size: 14px;
		padding: 20px 0px;
	}
	.footer-bottom {
		.flex {
			justify-content: center;
		}
	}
	
}
@media only screen and (max-width: 500px){
	.slide-menu-active {
		.site-content-menu-slide {
			right: 240px;
		}
	}
	.slide-menu {
		width: 240px;
		right: -240px;
	}
}
@media only screen and (max-width: 300px){
	header a.logo{
		padding-right:50px;
	}
}