/*============ shared index  ============*/
.t-shared-index {
	.page-body.empty {
		padding: 0px;
		padding-top: 40px;
	}
}

.list-wrap {
	padding-bottom: 5%;
	.contentwide {
		padding-top: 0px;
	}
}

.imgbg {
	@include responsive_square;
	color: #FFF;
	background-color: $light-grey;
	img,
	.hover  {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	img {
		object-fit: contain;
		height: 100% !important;
		z-index: 0;
	}
	&.fallback {
		img {
			width: 50%;
			height: auto !important;
			top: 50%;
			left: 50%;
			margin-top: -25%;
			margin-left: -25%;
		}
	}
	.hover {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: get_opacity("navy", 0.82);
		z-index: 1;
		opacity: 0;
		transition: opacity $quick ease;
		> span {
			@include button_hollow($txt: "navy", $colour-name: "#FFF");
			font-size: 18px;
			letter-spacing: 1px;
			padding: 10px;
		}
	}
}
.t-services {
	.list.flex {
		justify-content: center;
	}
}
.list.flex {
	justify-content: center;
	justify-content: space-evenly;
}
div.item {
	position: relative;
	width: 25%;
	padding: 10px 5px;
	box-sizing: border-box;
	&::after { /* border-bottom */
		content:"";
		position: absolute;
		bottom: 10px;
		left: 5px;
		display: block;
		width: calc(100% - 10px);
		height: 1px;
		background-color: var(--navy);
		transition: background-color $quick ease;
	}
	a {
		color: inherit;
		text-decoration: none;
	}
	.title {
		padding: 15px 0px;
		border-top: 1px solid var(--navy);
		margin-top: 20px;
		transition: color $quick ease, border-color $quick ease;
		h4 {
			line-height: 1;
			margin: 0px;
		}
	}
	&:hover {
		&::after { /* bottom line */
			background-color: var(--orange);
		}
		.hover {
			opacity: 1;
		}
		.title {
			color: var(--orange);
			border-color: var(--orange);
		}
	}
	/** category colour hovers **/
	&.orange {
		.imgbg {
			.hover {
				background-color: get_opacity("orange", 0.82);
				> span {
					@include button_hollow($txt: "orange", $colour-name: "#FFF");
				}
			}
			//default lines + txt already orange
		}
	}
	&.turquoise {
		.imgbg {
			.hover {
				background-color: get_opacity("turquoise", 0.82);
				> span {
					@include button_hollow($txt: "turquoise", $colour-name: "#FFF");
				}
			}
		}
		&:hover {
			&::after { /* bottom line */
				background-color: var(--turquoise);
			}
			.title {
				color: var(--turquoise);
				border-color: var(--turquoise);
			}
		}
	}
	&.teal {
		.imgbg {
			.hover {
				background-color: get_opacity("teal", 0.82);
				> span {
					@include button_hollow($txt: "teal", $colour-name: "#FFF");
				}
			}
		}
		&:hover {
			&::after { /* bottom line */
				background-color: var(--teal);
			}
			.title {
				color: var(--teal);
				border-color: var(--teal);
			}
		}
	}
	&.teal-blue {
		.imgbg {
			.hover {
				background-color: get_opacity("teal-blue", 0.82);
				> span {
					@include button_hollow($txt: "teal-blue", $colour-name: "#FFF");
				}
			}
		}
		&:hover {
			&::after { /* bottom line */
				background-color: var(--teal-blue);
			}
			.title {
				color: var(--teal-blue);
				border-color: var(--teal-blue);
			}
		}
	}
	&.sky-blue {
		.imgbg {
			.hover {
				background-color: get_opacity("sky-blue", 0.82);
				> span {
					@include button_hollow($txt: "sky-blue", $colour-name: "#FFF");
				}
			}
		}
		&:hover {
			&::after { /* bottom line */
				background-color: var(--sky-blue);
			}
			.title {
				color: var(--sky-blue);
				border-color: var(--sky-blue);
			}
		}
	}
/** service customisation **/
	&.pce_service {
		width: 50%;
		padding: 0px;
		/** update default - like navy **/
		color: #FFF;
		background-color: var(--navy);
		&::after {
			display: none;
		}
		.imgbg {
			&::before { // different ratio
				padding-top: 70%;
			}
			.hover > span {
				display: none;
			}
			&.fallback {
				img {
					width: 40%;
					margin-top: -20%;
					margin-left: -20%;
				}
			}
		}
		.title {
			position: relative;
			text-align: center;
			padding: 30px 50px;
			border-top: 0px;
			margin-top: 0px;
			&::after { /* arrow */
				content:"";
				position: absolute;
				top: 50%;
				margin-top: -10px;
				right: 20px;
				width: 20px;
				height: 20px;
				background-image: url('../assets/icon-arrow.svg');
				background-size: contain;
				background-position: center;
			}
		}
		&:hover {
			.title {
				color: #FFF !important;
			}
		}
		/** category colours **/
		&.orange {
			background-color: var(--orange);
		}
		&.turquoise {
			background-color: var(--turquoise);
		}
		&.teal {
			background-color: var(--teal);
		}
		&.teal-blue {
			background-color: var(--teal-blue);
		}
		&.sky-blue {
			background-color: var(--sky-blue);
		}
	}
/** staff customisation **/
	&.staff {
		cursor: pointer; // no-link
	}
}

/** no results **/
.center {
	text-align: center;
}


/** pager style **/
.pager {
    list-style: none;
    padding-left: 0px;
    display: flex;
    justify-content: center;
    gap: 1rem;
    
    li {
	    > a {
	        @include button_hollow;
	        min-width: 0px;
	        min-width: 30px;
	        height: 30px;
	        padding: 5px 2px;
	        box-sizing: border-box;
	    }
	    &.active {
	        > a {
	            color: #FFF;
	            background-color: #ccc;
	            border-color: #ccc;
	        }
	    }
        &.disabled {
            display: none;
        }
    }
}


/*======= testimonial index =======*/
.list-wrap {
	.testimonial {
		border-bottom: 1px solid $grey;
		margin-bottom: 40px;
		&:last-of-type {
			border-bottom: none;
		}
	}
}

/*========== staff index ==========*/
h3.title {
	max-width: 1200px;
	margin: 0px auto;
}
.staff-modal-overlay {
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100vh;
	overflow-y: auto;
	background-color: get_opacity("navy", 0.64);
	box-sizing: border-box;
	z-index: 9999;
	&.active {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 30px;
	}
}
.staff-modal {
	display: none;
	@include content_wide;
	padding-top: 0px;
	background-color: #FFF;
	button#close-button {
		position: relative;
		display: block;
		width: 100%;
		padding: 0px;
		height: 30px;
		text-align: right;
		border: 0px;
		background-color: transparent;
		color: #FFF;
		&:hover {
			&::before,
			&::after {
				background-color: var(--turquoise);
			}
		}
		&::before,
		&::after {
			content:"";
			position: absolute;
			top: 15px;
			right: -25px;
			display: block;
			width: 30px;
			height: 2px;
			background-color: var(--navy);
			transition: background-color $quick ease;
		}
		&::before {
			transform: rotate(45deg);
		}
		&::after {
			transform: rotate(-45deg);
		}
	}
	&.active {
		display: block;
	}
	.flex {
		gap: 40px 30px;
		align-items: flex-start;
		> div {
			display: inline-block;
			flex-grow: 1;
			flex-shrink: 1;
		}
	}
	.col-img {
		width: clamp(200px, 30%, 400px);
	}
	.profile {
		width: 60%;
		box-sizing: border-box;
		.title,
		.position{
			line-height: 1;
			margin: 0px;
		}
		.title {
			margin-bottom: 5px;
		}
		.position {
			&::after { /* underline */
				content:"";
				display: block;
				margin-top: 1em;
				height: 3px;
				width: 45px;
				border-radius: 3px;
				background-color: var(--navy);
			}
		}
	}
}

/** philosophy banner **/
.banner-icon-nav {
	background-color: var(--tint);
	.banner-navigation {
		position: relative;
		display: block !important;
		background-color: var(--navy);
		> div.banner-navigation-body {
			/* override generic */
			@include content_wrap;
			background-color: var(--navy);
			padding: 0px;
			/* custom */
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	.banner-nav-item {
		/* override dots */
		display: inline-block;
		width: 25%;
		height: auto;
		border: 0px;
		border-radius: 0px !important;
		margin: 0px;
		transition: transform $quick;
		&.active {
			background-color: inherit;
		}
		&:hover {
			background-color: inherit;
			transform: scale(1.1);
		}
		@include responsive_square;
		/** our banner image */
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		> span {
			display: none;
		}
		/** brand colours **/
		&[data-colour="turquoise"]{
			background-color: var(--turquoise);
		}
		&[data-colour="sky-blue"]{
			background-color: var(--sky-blue);
		}
		&[data-colour="orange"]{
			background-color: var(--orange);
		}
		&[data-colour="teal"]{
			background-color: var(--teal);
		}
		&[data-colour="teal-blue"]{
			background-color: var(--teal-blue);
		}
		&[data-colour="navy"]{
			background-color: var(--navy);
		}
	}
	.banner-stage {
		/* full screen */
		display: flex;
		flex-direction: column;
		background-color: var(--navy);
	}
	.banner {
		/** fill height **/
		flex-grow: 1;
		/* full screen */
		display: none;
		position: relative;
	    text-decoration: none;
	    background-position:center;
	    background-size:cover;
	    overflow: hidden;
	    z-index: 0;
	    opacity: 0;
	    transition: opacity $quick ease;
	    &.active {
	        display: flex;
	        min-height: 300px;
	        opacity: 1;
	        z-index: 1;
	        .banner-content {
	            display: flex;
	            &:after {
	                animation: draw_line 7s forwards linear;
	            }
	        }
	    }
	}
	.banner-content {
		@include content_wrap;
		padding-top: 40px;
		padding-bottom: 80px;
		display: flex;
		display: none;
	    flex-direction: column;
	    justify-content: center;
	    align-items: center;
	    &:after { /* the banner swap timer */
	        content:"";
	        position: absolute;
	        left: 0px;
	        bottom: 75px;
	        max-width: 100%;
	        width: 3px;
	        height: 3px;
			background-color: #FFF;
	    }
		> span {
			display: block;
		}
		.banner-name {
			font-size: 2em;
			font-family: var(--font-title);
			line-height: 1.2;
			margin-bottom: 20px;
		}
	}
}
@keyframes draw_line {
	0% {
		width: 3px;
	}
	/* see the line stop */
	98% {
		width: 100%;
	}
	100% { /* stay there */
		width: 100%;
	}
}

/*============ Project index  ============*/
.t-shared-index.t-project {
	/* the filters below have a contentwrap around */
	.page-body.empty {
		padding-top: 10px;
	}
}
/** category filters **/
nav.filters {
	margin-left: -5px;
	margin-right: -5px;
	h3 {
		margin: 0px;
	}
	li {
		margin: 5px;
		a {
			@include button_hollow;
			border-width: 2px;
			min-width: 80px;
		}
	/* brand colours */
		&.navy { // this is default, but declare anyway in case that changes
			a {
				@include button_hollow($colour-name: "navy");
				border-width: 2px;
				min-width: 80px;
			}
		}
		&.turquoise {
			a {
				@include button_hollow($colour-name: "turquoise");
				border-width: 2px;
				min-width: 80px;
			}
		}
		&.sky-blue {
			a {
				@include button_hollow($colour-name: "sky-blue");
				border-width: 2px;
				min-width: 80px;
			}
		}
		&.orange {
			a {
				@include button_hollow($colour-name: "orange");
				border-width: 2px;
				min-width: 80px;
			}
		}
		&.teal {
			a {
				@include button_hollow($colour-name: "teal");
				border-width: 2px;
				min-width: 80px;
			}
		}
		&.teal-blue {
			a {
				@include button_hollow($colour-name: "teal-blue");
				border-width: 2px;
				min-width: 80px;
			}
		}
	}
}

/*============ shared item  ============*/
.t-news,
.t-project {
	/* reduce banners on certain types */
	.page-graphic.has-img {
		min-height: 300px;
		min-height: clamp(200px, 16vw, 400px);
	}
}

.wrap {
	position: relative;
	min-height: 200px; // for share links
}
.share {
	position: absolute;
	display: inline-block;
	width: 30px;
	top: 50%;
	margin-top: -60px;
	right: 0px;
	color: #FFF;
	background-color: var(--turquoise);
	padding: 0px;
	box-sizing: border-box;
	a {
		display: inline-block;
		text-align: center;
		text-decoration: none;
	    width: 30px;
	    padding: 5px 8px;
	    box-sizing: border-box;
	    margin: 2px;
	}
	img {
		max-height: 15px;
		margin: 0 auto;
	}
}
.item-icon {
	max-width: 50%;
	float: right;
	margin-left: 30px;
	margin-bottom: 30px;
}
/**** service item ****/
.after_item {
	// in main
	@include class_buttons;
}

/*============ Media Queries  ============*/

@media only screen and (max-width:1260px) {
/*============ shared item  ============*/
	.wrap {
		text-align: right;
		.page-body {
			text-align: left;
		}
	}
	.share {
		position: relative;
		top: 0px;
		left: 0px;
		width: auto;
		margin-top: 0px;
	}
}


@media only screen and (max-width: 960px) {
/*============ shared index  ============*/
	div.item {
		width: 32%;
	}
/*** item **/
	.t-news,
	.t-project {
		.page-graphic.has-img {
			min-height: 200px;
			min-height: clamp(100px, 20vw, 200px);
		}
	}
}

@media only screen and (max-width: 760px) {
/*============ shared index  ============*/
	/* filters */
	nav.filters {
		text-align: center;
		margin: 0px;
		ul {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
	}
	/* list */
	div.item {
		width: 50%;
		&.pce_service { /* full */
			width: 100%;
			max-width: 400px;
		}
	}
/*========== staff index ==========*/
	.staff-modal-overlay.active {
		display: block;
	}
	.staff-modal {
		.imgbg {
			width: 200px;
			min-width: 0px;
		}
		.profile {
			width: 100%;
		}
	}
/*============ shared item  ============*/
	.item-icon {
		display: block;
		max-width: 100%;
		float: none;
		margin: 0px auto;
	}
}


@media only screen and (max-width: 500px) {
/*============ shared index  ============*/
	div.item {
		width: 100%;
		max-width: 300px;
	}
}

@media only screen and (max-width: 300px) {
	nav.filters {
		li {
			a {
				padding: 10px;
			}
			&.turquoise,
			&.orange,
			&.teal,
			&.teal-blue,
			&.sky-blue,
			&.navy {
				a {
					padding: 10px;
				}
			}
		}
	}
	.page-body{
		padding: 30px 15px;
	}
}