/** css vars - can be changed @ run time **/
:root {
	/* brand colours */
	--navy-hs: 198.6, 63.6%;
	--navy-l: 12.9%;
	--navy: hsl(var(--navy-hs), var(--navy-l)); //#0c2936;

	--turquoise-hs: 177.4, 32.1%;
	--turquoise-l: 57.3%;
	--turquoise: hsl(var(--turquoise-hs), var(--turquoise-l)); //#6fb5b2;

	--teal-hs: 191.3, 22.9%;
	--teal-l: 41.2%;
	--teal: hsl(var(--teal-hs), var(--teal-l)); //#517881;

	--teal-blue-hs: 197.3, 44.6%;
	--teal-blue-l: 29%;
	--teal-blue: hsl(var(--teal-blue-hs), var(--teal-blue-l)); //#29586b;

	--sky-blue-hs: 197.4, 54.8%;
	--sky-blue-l: 56.7%;
	--sky-blue: hsl(var(--sky-blue-hs), var(--sky-blue-l)); //#54AACD;

	--orange-hs: 3.9, 80.8%;
	--orange-l: 59.2%;
	--orange: hsl(var(--orange-hs), var(--orange-l)); //#eb4e43;

	/* text settings */
	--font-body: 'Montserrat', sans-serif;
	--font-heading: 'Pragati Narrow', sans-serif;
	--font-title: 'Zilla Slab', sans-serif;

	/* bg colours */
	--tint-hs: 0, 0%; //#F5F5F5 converted to hsl
	--tint-l: 95%;
	--tint: hsl(var(--tint-hs), var(--tint-l));
	--tint2: hsl(var(--tint-hs), calc(var(--tint-l) - 10%));
	--tint3: hsl(var(--tint-hs), calc(var(--tint-l) - 20%));
}

/** sass vars and settings - @ build time **/
$light-grey: #CCC;
$grey: #707070;

/* timing */
$quick: 0.3s;
$mid: 0.5s;

$border-radius: 5px;

/* common functions */
@function colour($colour-name){
	@if #{$colour-name} != 'transparent' and not str-index(#{$colour-name}, "#") {
		@return var(--#{$colour-name});
	} @else {
		@return #{$colour-name};
	}
}
@function get_darker($colour-name){
	@if #{$colour-name} != 'transparent' and not str-index(#{$colour-name}, "#") {
		@return  hsl(var(--#{$colour-name}-hs), calc( var(--#{$colour-name}-l) - 20%));
	} @else {
		@return #{$colour-name};
	}
}
@function get_opacity($colour-name, $alpha){
	@return hsla(var(--#{$colour-name}-hs), var(--#{$colour-name}-l), $alpha);
}

//quote it if we want to use it as a string - sass will pass css colour names as colours
@mixin button_solid($txt: #FFF, $colour-name: "orange") {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-family: var(--font-heading);
    font-size: 1.125rem;
	letter-spacing: 0.432px;
    line-height: 1;
    font-weight: 600;
    text-decoration: none;
    padding: 10px 20px;
    padding-right: 30px;
    min-width: 100px;
    max-width: 100%;
    color: colour($txt);
    background-color: colour($colour-name);
    border: 1px solid colour($colour-name);
    box-sizing: border-box;
    border-radius: $border-radius;
    transition: color $quick ease-in-out, background-color $quick ease-in-out;
    cursor: pointer;
    margin: 1px;
    &:hover {  // darken background
        background-color: get_darker($colour-name);
    }
}
//quote the colour name - sass will pass css colour names as colours
@mixin button_hollow($txt: #FFF, $colour-name: "navy") {
    @include button_solid($colour-name, transparent);
    border-color: colour($colour-name);
    &:hover,
    &.active { // make solid <-active used on nav.filters
        color: colour($txt);
        background-color: colour($colour-name);
    }
}
@mixin white_txt(){
	color: #FFF;
	.underline {
		&::after {
			background-color: #FFF;
		}
	}
	.button_solid {
		@include button_solid($colour-name: #FFF);
	}
	.button_hollow {
		@include button_hollow($txt: #000, $colour-name: #FFF);
	}
}

@mixin overlay($colour-name: "navy", $op: 0.5, $mode: multiply) {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: get_opacity($colour-name, $op);
    mix-blend-mode: $mode;
    z-index: 1;
}

@mixin responsive_square {
    position: relative;
    &::before {
        content:"";
        display: block;
        width: 100%;
        padding-top: 100%;
    }
}

@mixin dot($col: var(--navy)){
	display: inline-block;
	width: 15px;
	height: 15px;
	border: 1px solid $col;
	border-radius: 50%;
	transition: background-color $quick ease;
	margin: 5px;
	&:hover {
		background-color: var(--sky-blue);
	}
	&.active {
		background-color: $col;
	}
}

@mixin content_wide(){
	width: 1800px;
	padding: 30px;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
}

@mixin content_wrap(){
	position: relative;
	width: 1260px;
	padding: 30px;
	max-width: 100%;
	box-sizing: border-box;
    margin: 0 auto;
}
