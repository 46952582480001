html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */
@import "vars";

body {
    padding:0;
    margin:0;
    font-size: 16px;
    line-height: 1.6;
}
img {
    border:none;
}


.contentwrap {
    @include content_wrap;
}
.contentwide {
	@include content_wide;
}

h3.contentwide {
	padding: 0px 40px;
}
.flex {
	display: flex;
	justify-content: center;
	justify-content: space-evenly;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	box-sizing: border-box;
	&.title {
		justify-content: center;
		justify-content: space-evenly;
		align-items: center;
	}
}

/* in footer + contact page */
.office {
	padding: 10px;
	h4 {
		font-size: 1.3em;
		margin: 0px;
		margin-bottom: 10px;
	}
	.phone,
	.email {
		a {
			text-decoration: none;
            color: inherit;
		}
	}
}

/* image macros */
picture.bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 0;
    img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}

@import "header_footer";
@import "forms";
@import "pages";
@import "blocks";
@import "other_content";
@import "banner_animate";

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1280px){
	.contentwide {
		padding: 30px;
	}
}

@media only screen and (max-width: 760px) {

    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

    }
}
@media only screen and (max-width: 300px) {
    .contentwrap, .contentwide {
        padding-left:15px;
        padding-right:15px;
    }
}
@import "helpers";
@import "print";